import { LoginStateEnum } from "../../../../model/enum/LoginStateEnum";
import _languageStore from "../../../../store/LanguageStore";
import _loginStore from "../../../../store/LoginStore";
import style from "./RegisterReturn.module.scss";

const RegisterReturn = () => {
   const text = _languageStore.getText();

   const goToLogin = () => {
      _loginStore.changeState(LoginStateEnum.LOGIN);
   };
   return (
      <div className={style["register-return-wraper"]}>
         {text.login.alreadyHaveAcc}
         <div className={style["link-alike"]} onClick={goToLogin}>
            {text.login.signIn}
         </div>
      </div>
   );
};
export default RegisterReturn;
