import { makeAutoObservable } from "mobx";
import Ilanguage from "../assets/i18n/Ilanguage";

class LanguageStore {
   private langEn: string;
   private langRs: string;
   private _language: string;
   public get language(): string {
      return this._language;
   }
   public set language(value: string) {
      this._language = value;
   }
   getText: () => Ilanguage;

   constructor() {
      this.langEn = "en";
      this.langRs = "rs";
      this._language = this.langEn; // Default language

      this.getText = () => {
         //It gets file depending on language selected and returng prop for current page
         const langFile = require(`../assets/i18n/${this.language}`)[
            this._language
         ];

         return langFile;
      };
      makeAutoObservable(this);
   }

   setLanguage(lang: string) {
      this.language = lang;
   }
}

const _languageStore = new LanguageStore();
export default _languageStore;
