import { useEffect, useState } from "react";
import { ForgotPasswordStateEnum } from "../../../../model/enum/ForgotPasswordStateEnum";
import _loginStore from "../../../../store/LoginStore";
import style from "./ForgotPassword.module.scss";
import CodeConfirmation from "./codeConfirmation/CodeConfirmation";
import EmailChecker from "./emailChecker/EmailChecker";

const ForgotPassword = () => {
   const [state, setState] = useState(ForgotPasswordStateEnum.SEND_MAIL);
   const [displayElement, setDisplayElement] = useState(<EmailChecker />);

   _loginStore.forgotPasswordState = setState;

   useEffect(() => {
      switch (state) {
         case ForgotPasswordStateEnum.SEND_MAIL:
            setDisplayElement(<EmailChecker />);
            break;
         case ForgotPasswordStateEnum.TYPE_CODE:
            setDisplayElement(<CodeConfirmation />);
            break;
         case ForgotPasswordStateEnum.RE_ENGER_PASS:
            setDisplayElement(<CodeConfirmation />);
            break;
         default:
            break;
      }
   }, [state]);

   return (
      <div className={style["forgot-password-wraper"]}>
         {/* <EmailChecker /> */}
         {displayElement}
      </div>
   );
};
export default ForgotPassword;
