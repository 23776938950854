import IconButton from "../../../../common/iconButton/IconButton";
import _languageStore from "../../../../store/LanguageStore";
import style from "./LoginWithMedia.module.scss";
const googleLogo = require("./../../../../assets/logo/googleLogo.png");
const facebookLogo = require("./../../../../assets/logo/facebookLogo.png");
const phoneCheckIcon = require("./../../../../assets/logo/phoneCheckIcon.png");

const LoginWithMedia = () => {
   const text = _languageStore.getText();
   return (
      <div className={style["login-with-media-wrapper"]}>
         <div className={style["login-with-media-text"]}>
            {text.login.header}
         </div>
         <IconButton
            name={text.login.googleButton}
            action={() => {
               console.log(text.login.googleButton);
            }}
            imageSrc={googleLogo}
         />
         <IconButton
            name={text.login.facebookButton}
            action={() => {
               console.log(text.login.facebookButton);
            }}
            imageSrc={facebookLogo}
         />
         <IconButton
            name={text.login.phoneButton}
            action={() => {
               console.log(text.login.phoneButton);
            }}
            imageSrc={phoneCheckIcon}
         />
      </div>
   );
};
export default LoginWithMedia;
