import style from "./IconButton.module.scss";
import { IIconButton } from "./IIconButton";

/*
 * BUTTON COMPONENT
 * Used for on page actions
 */

const IconButton = ({
   name,
   action,
   index,
   imageSrc,
   showName = true,
}: IIconButton) => {
   return (
      <div className={style["icon-button-wrapper"]}>
         <button
            /* it helps creating unique ID for every rendered componet for easy automated testing */
            id={
               index &&
               `button-${name.replace(" ", "-").toLowerCase()}-${index}`
            }
            type="button"
            onClick={(x) => {
               action(x);
            }}
         >
            <div className={style["icon-button-img-wrapper"]}>
               <img
                  className={style["icon-button-img"]}
                  src={imageSrc}
                  alt={name}
               />
            </div>
            {showName && <span>{name}</span>}
         </button>
      </div>
   );
};
export default IconButton;
