/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
import { makeAutoObservable } from "mobx";
import { ForgotPasswordStateEnum } from "../model/enum/ForgotPasswordStateEnum";
import { LoginStateEnum } from "../model/enum/LoginStateEnum";

class LoginStore {
   public forgotPasswordState: React.Dispatch<
      React.SetStateAction<ForgotPasswordStateEnum>
   >;
   public curentState: LoginStateEnum;
   public setCurrentBotomState: React.Dispatch<
      React.SetStateAction<LoginStateEnum>
   >;
   public setCurrentTopState: React.Dispatch<
      React.SetStateAction<LoginStateEnum>
   >;
   public changeState: (en: LoginStateEnum) => void;

   private _currentPath: string;
   public get currentPath(): string {
      return this._currentPath;
   }
   public set currentPath(value: string) {
      this._currentPath = value;
   }

   //REGISTRATION
   public email: string;
   public password: string;
   public secondPassword: string;
   public setEmail: (email: string) => void;
   public setPassword: (email: string) => void;
   public setSecondPassword: (email: string) => void;

   public isEmailWrong: boolean;
   public setIsEmailWrong: (x: boolean) => void;

   public isPasswordWrong: boolean;
   public setIsPasswordWrong: (x: boolean) => void;

   public setWrongEmail: React.Dispatch<React.SetStateAction<boolean>>;
   public setWrongPassword: React.Dispatch<React.SetStateAction<boolean>>;

   public isSubmitDisabled: boolean;
   public setIsSubmitDisabled: (x: boolean) => void;

   public firstName: string;
   public setFirstName: (firstName: string) => void;

   public lastName: string;
   public setLastName: (firstName: string) => void;

   public address: string;
   public setAddress: (firstName: string) => void;

   constructor() {
      this.setCurrentBotomState = () => {};
      this.forgotPasswordState = () => {};
      this.setCurrentTopState = () => {};
      this.changeState = (en: LoginStateEnum) => {
         this.setCurrentTopState(en);
         this.setCurrentBotomState(en);
      };
      this.curentState = LoginStateEnum.LOGIN;
      this._currentPath = "login";

      //REGISTRATION
      this.email = "";
      this.password = "";
      this.secondPassword = "";
      this.setWrongEmail = () => {};
      this.setWrongPassword = () => {};

      this.setEmail = (email: string) => {
         this.email = email;
      };
      this.setPassword = (password: string) => {
         this.password = password;
      };
      this.setSecondPassword = (secondPassword: string) => {
         this.secondPassword = secondPassword;
      };

      this.isEmailWrong = false;
      this.setIsEmailWrong = (x: boolean) => {
         this.isEmailWrong = x;
      };

      this.isPasswordWrong = false;
      this.setIsPasswordWrong = (x: boolean) => {
         this.isPasswordWrong = x;
      };

      this.isSubmitDisabled = true;
      this.setIsSubmitDisabled = (x: boolean) => {
         this.isSubmitDisabled = x;
      };

      this.firstName = "";
      this.setFirstName = (firstName: string) => {
         this.firstName = firstName;
      };
      this.lastName = "";
      this.setLastName = (lastName: string) => {
         this.lastName = lastName;
      };

      this.address = "";
      this.setAddress = (address: string) => {
         this.address = address;
      };
      makeAutoObservable(this);
   }
}

const _loginStore = new LoginStore();
export default _loginStore;
