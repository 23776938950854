/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import Input from "../../../../../common/input/Input";
import _languageStore from "../../../../../store/LanguageStore";
import _loginStore from "../../../../../store/LoginStore";
import style from "./RegisterAccount.module.scss";

const RegisterAccount = () => {
   const {
      email,
      password,
      secondPassword,
      setEmail,
      setPassword,
      setSecondPassword,
      isEmailWrong,
      isPasswordWrong,
      setIsSubmitDisabled,
   } = _loginStore;
   const [passwordClasses, setPasswordClasses] = useState(
      `${style["register-account-input"]} ${style["register-account-input-right"]}`,
   );
   const [emailClasses, setEmailClasses] = useState(
      `${style["register-account-input"]} ${style["register-account-input-right"]}`,
   );

   const text = _languageStore.getText();
   useEffect(() => {
      if (isPasswordWrong) {
         setPasswordClasses(
            `${style["register-account-input"]} ${style["register-account-input-wrong"]}`,
         );
      } else {
         setPasswordClasses(
            `${style["register-account-input"]} ${style["register-account-input-right"]}`,
         );
      }

      if (isEmailWrong) {
         setEmailClasses(
            `${style["register-account-input"]} ${style["register-account-input-wrong"]}`,
         );
      } else {
         setEmailClasses(
            `${style["register-account-input"]} ${style["register-account-input-right"]}`,
         );
      }
   }, [isEmailWrong, isPasswordWrong]);

   useEffect(() => {
      if ([email, password, secondPassword].find((x) => x === "") === "") {
         setIsSubmitDisabled(true);
      } else {
         setIsSubmitDisabled(false);
      }
   }, [email, password, secondPassword]);

   return (
      <div className={style["register-account-wrapper"]}>
         <div>{text.login.register.signUpWithNewAcc}</div>
         <div className={style["register-account-wrapper"]}>
            <Input
               type="text"
               value={email}
               action={(x) => {
                  setEmail(x);
               }}
               placeholder={text.login.email}
               classes={emailClasses}
            />
         </div>
         {isPasswordWrong && (
            <div className={style["register-account-text-wrong"]}>
               {text.login.passNotMatch}
            </div>
         )}
         <div className={style["register-account-wrapper"]}>
            <Input
               type={`password`}
               value={password}
               action={(x) => {
                  setPassword(x);
               }}
               placeholder={text.login.pasword}
               classes={passwordClasses}
            />
         </div>

         <div className={style["register-account-wrapper"]}>
            <Input
               type={`password`}
               value={secondPassword}
               action={(x) => {
                  setSecondPassword(x);
               }}
               placeholder={text.login.confirmPasword}
               classes={passwordClasses}
            />
         </div>
      </div>
   );
};
export default observer(RegisterAccount);
