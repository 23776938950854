import Map from "../../composits/map/Map";
import { UrlEnum } from "../../model/enum/UrlEnum";
import style from "./Home.module.scss";
import TopBar from "./topBar/TopBar";

const HomePage = () => {
   return (
      <div className={style.home_page_wraper}>
         <TopBar />
         <Map />
         <a href={UrlEnum.LOGIN}>go to login</a>
      </div>
   );
};
export default HomePage;
