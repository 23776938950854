import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../common/button/Button";
import { RegisterStateEnum } from "../../../../model/enum/RegisterStateEnum";
import { UrlEnum } from "../../../../model/enum/UrlEnum";
import _languageStore from "../../../../store/LanguageStore";
import _loginStore from "../../../../store/LoginStore";
import style from "./Register.module.scss";
import RegisterAccount from "./registerAccount/RegisterAccount";
import RegisterInformations from "./registerInformations/RegisterInformations";

const Register = () => {
   const text = _languageStore.getText();
   const navigate = useNavigate();
   const {
      email,
      password,
      secondPassword,
      isEmailWrong,
      isPasswordWrong,
      setIsEmailWrong,
      setIsPasswordWrong,
      isSubmitDisabled,
      firstName,
      lastName,
      address,
   } = _loginStore;

   const [curentRegisterState, setCurentRegisterState] = useState(
      RegisterStateEnum.ACCOUNT_INFO,
   );

   const submitRegister = () => {
      if (password !== secondPassword) {
         setIsPasswordWrong(true);
      } else {
         setIsPasswordWrong(false);
      }
      if (!email) {
         setIsEmailWrong(true);
      } else {
         setIsEmailWrong(false);
      }
      if (
         !isEmailWrong &&
         !isPasswordWrong &&
         curentRegisterState === RegisterStateEnum.ACCOUNT_INFO
      ) {
         return setCurentRegisterState(RegisterStateEnum.PERSONAL_INFO);
      } else if (firstName && lastName && address) {
         navigate(UrlEnum.HOME);
      }
   };

   return (
      <div className={style["register-section-wrapper"]}>
         {curentRegisterState === RegisterStateEnum.ACCOUNT_INFO && (
            <RegisterAccount />
         )}
         {curentRegisterState === RegisterStateEnum.PERSONAL_INFO && (
            <RegisterInformations />
         )}
         <Button
            name={text.login.register.registerButton}
            isDisabled={isSubmitDisabled}
            action={() => {
               submitRegister();
            }}
            classes={style["register-sizer"]}
         />
      </div>
   );
};
export default observer(Register);
