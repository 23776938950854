import { Outlet } from "react-router-dom";
import styles from "./App.module.scss";
import Layout from "./composits/layout/Layout";

const App = () => {
   return (
      <div className={styles["App"]}>
         <Layout>
            <Outlet />
         </Layout>
      </div>
   );
};

export default App;
