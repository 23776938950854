import { UrlEnum } from "../../../model/enum/UrlEnum";
import _languageStore from "../../../store/LanguageStore";
import style from "./LoginFooter.module.scss";

const LoginFooter = () => {
   const text = _languageStore.getText();
   return (
      <div className={style["login-footer-wrapper"]}>
         <a className={style["login-footer-link"]} href={UrlEnum.LEGAL}>
            {text.legal.privacyPolicy}
         </a>
         <a className={style["login-footer-link"]} href={UrlEnum.LEGAL}>
            {text.legal.termOfService}
         </a>
      </div>
   );
};
export default LoginFooter;
