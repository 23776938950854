import style from "./TopBar.module.scss";
import TopBarLeft from "./topBarLeft/TopBarLeft";
import TopBarRight from "./topBarRight/TopBarRight";

const TopBar = () => {
   return (
      <div className={style.top_bar_wraper}>
         <div className={style.top_bar_container}>
            <div className={style.top_bar_left}>
               <TopBarLeft />
            </div>
            <div className={style.top_bar_right}>
               <TopBarRight />
            </div>
         </div>
      </div>
   );
};
export default TopBar;
