import { IInput } from "./IInput";
import styles from "./Input.module.scss";

/*
 * BASIC INPUT COMPONENT
 * Used for string and numeric inputs depending on Type passed with options "string" and "number"
 */

const Input = ({
   name,
   value,
   type,
   action,
   index,
   isDisabled = false,
   classes = "",
   placeholder,
}: IInput) => {
   return (
      <div className={`${classes} ${styles["input-wraper"]}`}>
         {name && <label>{name}</label>}
         <input
            placeholder={placeholder}
            type={type}
            disabled={isDisabled}
            /* it helps creating unique ID for every rendered componet for easy automated testing */
            id={
               index &&
               `input-${name && name.replace(" ", "-").toLowerCase() + "-"}${index}`
            }
            value={value}
            onChange={(e) => {
               /* code maps stirng as it is but on number when remuved maps null*/
               if (
                  type === "number" &&
                  e.target.value.toString().trim() === ""
               ) {
                  action(null);
               } else {
                  const value = e.target.value;
                  action(value);
               }
            }}
         />
      </div>
   );
};
export default Input;
