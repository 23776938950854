import React from "react";
import ReactDOM from "react-dom/client";
import {
   createBrowserRouter,
   Navigate,
   RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./index.css";
import { UrlEnum } from "./model/enum/UrlEnum";
import ErrorPage from "./pages/error/ErrorPage";
import HomePage from "./pages/home/HomePage";
import LegalPage from "./pages/legal/LegalPage";
import LoginPage from "./pages/login/LoginPage";

const router = createBrowserRouter([
   {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />, // Show an error page
      children: [
         {
            index: true, // This makes this route the default for the parent route
            element: <HomePage />,
         },
         {
            path: UrlEnum.LOGIN,
            element: <LoginPage />,
         },
         {
            path: UrlEnum.LEGAL,
            element: <LegalPage />,
         },
         // You can add more child routes here
      ],
   },
   // Catch-all route
   {
      path: "*",
      element: <Navigate replace to="/" />,
   },
]);

const root = ReactDOM.createRoot(
   document.getElementById("root") as HTMLElement,
);
root.render(
   <React.StrictMode>
      <RouterProvider router={router} />
   </React.StrictMode>,
);
