import style from "./LoginLogo.module.scss";
const logo = require("./../../../assets/logo/n4yLogo.png");

const LoginLogo = () => {
   return (
      <div className={style["login-logo-wrapper"]}>
         <img className={style["login-logo-img"]} src={logo} alt="need4You" />
      </div>
   );
};
export default LoginLogo;
