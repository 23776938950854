import style from "./TopBarLeft.module.scss";
const logo = require("./../../../../assets/logo/n4yLogo-header.png");

const TopBarLeft = () => {
   return (
      <div>
         <img className={style.top_bar_logo_img} src={logo} alt="need4You" />
      </div>
   );
};
export default TopBarLeft;
