import { useState } from "react";
import { LoginStateEnum } from "../../../model/enum/LoginStateEnum";
import _loginStore from "../../../store/LoginStore";
import ForgotPassReturn from "./forgotPassReturn/ForgotPassReturn";
import LoginInput from "./loginInput/LoginInput";
import RegisterReturn from "./registerReturn/RegisterReturn";

const LoginBottom = () => {
   const [currentState, setCurrentState] = useState(_loginStore.curentState);

   // eslint-disable-next-line @typescript-eslint/no-empty-function
   _loginStore.setCurrentBotomState = setCurrentState;

   switch (currentState) {
      case LoginStateEnum.LOGIN:
         return <LoginInput />;
      case LoginStateEnum.REGISTER:
         return <RegisterReturn />;
      case LoginStateEnum.FORGOT_PASS:
         return <ForgotPassReturn />;
      default:
         return <div></div>;
   }
};
export default LoginBottom;
