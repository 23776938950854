// src/assets/i18n/sr.js

import Ilanguage from "./Ilanguage";

export const rs: Ilanguage = {
   login: {
      header: "Dobrodošli na stranicu za prijavu",
      subheader: "Molimo unesite vaše podatke za prijavu",
      email: "Email",
      pasword: "Šifra",
      confirmPasword: "Potvrdi Šifru",
      loginButton: "PRIJAVI SE",
      needAcc: "Potreban ti je nalog?",
      registerLink: "Registruj se",
      googleButton: "Google",
      facebookButton: "Facebook",
      phoneButton: "Telefon",
      wrongEmailOrPass: "Pogrešan email/šifra.",
      forgotPasswordLink: "Resetuj šifru?",
      alreadyHaveAcc: "Već imate nalog?",
      signIn: "prijavi se",
      passNotMatch: "Lozinke se ne poklapaju. Pokušajte ponovo.",
      register: {
         signUpWithNewAcc: "Prijavite se sa novim nalogom",
         registerButton: "REGISTRUJ SE",
         firstName: "Ime",
         lastName: "Prezime",
         address: "Adresa",
         secondStep: "Skoro smo gotovi",
      },
      forgotPassword: {
         backToLogin: "Nazad na prijavu",
         enterEmail: "Unesite email adresu koja je povezana sa vašim nalogom.",
         requestReset: "Pošalji",
      },
   },
   homePage: {
      welcomeMessage: "Dobrodošli na početnu stranicu!",
      description: "Ovo je glavni deo naše aplikacije.",
   },
   utils: {
      or: "ili",
   },
   legal: {
      termOfService: "Uslovi korišćenja usluga",
      privacyPolicy: "Pravila o privatnosti",
   },
};
