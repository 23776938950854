import LegalHeader from "./leagalHeader/LegalHeader";
import style from "./LegalPage.module.scss";

const LegalPage = () => {
   return (
      <div className={style["legal-page-wrapper"]}>
         <LegalHeader />
      </div>
   );
};

export default LegalPage;
