import _languageStore from "../../../store/LanguageStore";
import style from "./LegalHeader.module.scss";
const LegalHeader = () => {
   const text = _languageStore.getText();

   return (
      <div className={style["legal-header-wrapper"]}>
         <div className={style["legal-header-nav"]}>
            <span>{text.legal.privacyPolicy}</span>
            <span>{text.legal.termOfService}</span>
         </div>
         <div className={style["legal-header-title"]}>
            <h1>{text.legal.privacyPolicy}</h1>
         </div>
      </div>
   );
};
export default LegalHeader;
