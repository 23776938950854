import { useState } from "react";
import Button from "../../../../../common/button/Button";
import Input from "../../../../../common/input/Input";
import { ForgotPasswordStateEnum } from "../../../../../model/enum/ForgotPasswordStateEnum";
import _languageStore from "../../../../../store/LanguageStore";
import _loginStore from "../../../../../store/LoginStore";
import style from "./EmailChecker.module.scss";

const EmailChecker = () => {
   const text = _languageStore.getText();
   const [email, setEmail] = useState("");
   const [submitDisabled, setSubmitDisabled] = useState(true);
   const [emailClasses, setEmailClasses] = useState(
      `${style["email-checker-input"]} ${style["email-checker-input-right"]}`,
   );
   const submit = () => {
      setEmailClasses(
         `${style["email-checker-input"]} ${style["email-checker-input-wrong"]}`,
      );
      _loginStore.forgotPasswordState(ForgotPasswordStateEnum.TYPE_CODE);
   };
   const setEmailAndAction = (x: string) => {
      setEmail(x);
      setSubmitDisabled(!x.trim());
   };

   return (
      <div className={style["email-checker-wraper"]}>
         <div>{text.login.forgotPassword.enterEmail}</div>
         <div className={style["email-checker-input"]}>
            <Input
               type="text"
               value={email}
               action={setEmailAndAction}
               placeholder={text.login.email}
               classes={emailClasses}
            />
         </div>
         <Button
            name={text.login.forgotPassword.requestReset}
            isDisabled={submitDisabled}
            action={submit}
            classes={style["register-sizer"]}
         />
      </div>
   );
};
export default EmailChecker;
