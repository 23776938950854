import _languageStore from "../../store/LanguageStore";
import style from "./OrSection.module.scss";

const OrSection = () => {
   const text = _languageStore.getText();
   return (
      <div className={style["or-section-wrapper"]}>
         <span className={style["or-section-line"]}></span>
         <span>{text.utils.or}</span>
         <span className={style["or-section-line"]}></span>
      </div>
   );
};
export default OrSection;
