import { useState } from "react";
import { LoginStateEnum } from "../../../model/enum/LoginStateEnum";
import _loginStore from "../../../store/LoginStore";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import LoginWithMedia from "./loginWithMedia/LoginWithMedia";
import Register from "./register/Register";

const LoginTop = () => {
   const [currentState, setCurrentState] = useState(_loginStore.curentState);

   // eslint-disable-next-line @typescript-eslint/no-empty-function
   _loginStore.setCurrentTopState = setCurrentState;

   switch (currentState) {
      case LoginStateEnum.LOGIN:
         return <LoginWithMedia />;
      case LoginStateEnum.REGISTER:
         return <Register />;
      case LoginStateEnum.FORGOT_PASS:
         return <ForgotPassword />;
      default:
         return <div></div>;
   }
};
export default LoginTop;
