import style from "./Button.module.scss";
import IButton from "./IButton";
/*
 * BASIC BUTTON COMPONENT
 * Used for on page actions
 */

const Button = ({
   name,
   action,
   index,
   classes,
   isDisabled = false,
}: IButton) => {
   return (
      <button
         className={`${style["common-button-wrapper"]} ${classes} ${isDisabled && style["common-button-disabled"]}`}
         disabled={isDisabled}
         /* it helps creating unique ID for every rendered componet for easy automated testing */
         id={index && `button-${name.replace(" ", "-").toLowerCase()}-${index}`}
         type="button"
         onClick={(x) => {
            action(x);
         }}
      >
         {name}
      </button>
   );
};
export default Button;
