import OrSection from "../../composits/orSection/OrSection";
import LoginBottom from "./loginBottom/LoginBotom";
import LoginFooter from "./loginFooter/LoginFooter";
import LoginLogo from "./loginLogo/LoginLogo";
import style from "./LoginPage.module.scss";
import LoginTop from "./loginTop/LoginTop";

const LoginPage = () => {
   return (
      <div className={style["login-page-wrapper"]}>
         <div>
            <LoginLogo />
            <LoginTop />
            <OrSection />
            <LoginBottom />
            <LoginFooter />
         </div>
      </div>
   );
};
export default LoginPage;
