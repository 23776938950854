import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFaButton } from "./IFaButton";

const FaButton = ({ action, faIcon, styleClass = "" }: IFaButton) => {
   return (
      <span className={`${styleClass}`}>
         <FontAwesomeIcon icon={faIcon} onClick={action} />
      </span>
   );
};
export default FaButton;
