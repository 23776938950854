import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer } from "react-leaflet";
import style from "./Map.mocule.scss";

export default function Map() {
   return (
      <div className={style.map_container}>
         <MapContainer
            className={style["leaflet-container"]}
            center={[45.241045, 19.882894]}
            zoom={20}
         >
            <TileLayer
               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
         </MapContainer>
      </div>
   );
}
