// src/assets/i18n/en.js

import Ilanguage from "./Ilanguage";

export const en: Ilanguage = {
   login: {
      header: "Sign up or Login with",
      subheader: "Please enter your credentials",
      email: "Email",
      pasword: "Pasword",
      confirmPasword: "Confirm Pasword",
      loginButton: "LOGIN",
      needAcc: "Need an account?",
      registerLink: "Register",
      googleButton: "Google",
      facebookButton: "Facebook",
      phoneButton: "Phone",
      wrongEmailOrPass: "Wrong email/password.",
      alreadyHaveAcc: "Already have an account?",
      forgotPasswordLink: "Forgot password?",
      signIn: "Sign in",
      passNotMatch: "The passwords do not match. Please try again.",
      register: {
         signUpWithNewAcc: "Sign up with new account",
         registerButton: "REGISTER",
         firstName: "First Name",
         secondStep: "We are almoste done",
         lastName: "Last Name",
         address: "Address",
      },
      forgotPassword: {
         backToLogin: "Back to login",
         enterEmail: "Enter the email address associated with your account.",
         requestReset: "Send",
      },
   },
   homePage: {
      welcomeMessage: "Welcome to the Home Page!",
      description: "This is the main area of our application.",
   },
   utils: {
      or: "or",
   },
   legal: {
      privacyPolicy: "Privacy Policy",
      termOfService: "Term Of Service",
   },
};
