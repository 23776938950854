import { LoginStateEnum } from "../../../../../model/enum/LoginStateEnum";
import _languageStore from "../../../../../store/LanguageStore";
import _loginStore from "../../../../../store/LoginStore";
import style from "./WrongPassOrEmail.module.scss";
const WrongPassOrEmail = () => {
   const text = _languageStore.getText();

   const goToForgotPass = () => {
      _loginStore.changeState(LoginStateEnum.FORGOT_PASS);
   };
   return (
      <div className={style["wrong-pass-or-email-wraper"]}>
         <div className={style["wrong-pass-or-email-text-container"]}>
            {" "}
            {text.login.wrongEmailOrPass}
         </div>
         <div
            className={`${style["wrong-pass-or-email-text-container"]} ${style["link-alike"]}`}
            onClick={goToForgotPass}
         >
            {text.login.forgotPasswordLink}
         </div>
      </div>
   );
};
export default WrongPassOrEmail;
