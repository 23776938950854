import { useRouteError } from "react-router-dom";
import RouteError from "./IRoutError";
const ErrorPage = () => {
   const error = useRouteError() as RouteError;
   console.error(error); // TODO make this log ony in dev

   return (
      <div id='error-page'>
         <h1>Oops!</h1>
         <p>Sorry, an unexpected error has occurred.</p>
         <p>
            <i>{error.statusText || error.message}</i>
         </p>
      </div>
   );
};
export default ErrorPage;
