import { LoginStateEnum } from "../../../../model/enum/LoginStateEnum";
import _languageStore from "../../../../store/LanguageStore";
import _loginStore from "../../../../store/LoginStore";
import style from "./ForgotPassReturn.module.scss";

const ForgotPassReturn = () => {
   const text = _languageStore.getText();
   const returnToLogin = () => {
      // eslint-disable-next-line
      _loginStore.changeState(LoginStateEnum.LOGIN);
   };
   return (
      <div className={style["forgot-pass-return-wraper"]}>
         <div className={style["link-alike"]} onClick={returnToLogin}>
            {text.login.forgotPassword.backToLogin}
         </div>
      </div>
   );
};
export default ForgotPassReturn;
