import { observer } from "mobx-react";
import Input from "../../../../../common/input/Input";
import _languageStore from "../../../../../store/LanguageStore";
import _loginStore from "../../../../../store/LoginStore";
import style from "./RegisterInformations.module.scss";

const RegisterInformations = () => {
   const text = _languageStore.getText();
   const {
      firstName,
      setFirstName,
      lastName,
      setLastName,
      address,
      setAddress,
   } = _loginStore;

   return (
      <div className={style["register-informations-wrapper"]}>
         <div>{text.login.register.secondStep}</div>
         <div className={style["register-informations-wrapper"]}>
            <Input
               type="text"
               value={firstName}
               action={setFirstName}
               placeholder={text.login.register.firstName}
               classes={style["register-informations-input"]}
            />

            <Input
               type="text"
               value={lastName}
               action={setLastName}
               placeholder={text.login.register.lastName}
               classes={style["register-informations-input"]}
            />

            <Input
               type="text"
               value={address}
               action={setAddress}
               placeholder={text.login.register.address}
               classes={style["register-informations-input"]}
            />
         </div>
      </div>
   );
};
export default observer(RegisterInformations);
