import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../common/button/Button";
import FaButton from "../../../../common/faButton/FaButton";
import Input from "../../../../common/input/Input";
import { LoginStateEnum } from "../../../../model/enum/LoginStateEnum";
import { UrlEnum } from "../../../../model/enum/UrlEnum";
import _languageStore from "../../../../store/LanguageStore";
import _loginStore from "../../../../store/LoginStore";
import _userMock from "../../../../utils/mockData/UserMock";
import style from "./LoginInput.module.scss";
import WrongPassOrEmail from "./wrongPassOrEmail/WrongPassOrEmail";

const LoginInput = () => {
   const text = _languageStore.getText();

   const navigate = useNavigate();

   const [showPassword, setShowPassword] = useState(false);
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [isWrongPass, setIsWrongPass] = useState(false);
   const [inputClasses, setInputClasses] = useState(
      `${style["login-input"]} ${style["login-input-right"]}`,
   );

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };
   const submitLogin = () => {
      if (_userMock.email !== email || _userMock.password !== password) {
         setIsWrongPass(true);
         setInputClasses(
            `${style["login-input"]} ${style["login-input-wrong"]}`,
         );
      } else {
         navigate(UrlEnum.HOME);
      }
   };
   const goToRegister = () => {
      _loginStore.changeState(LoginStateEnum.REGISTER);
   };
   return (
      <div className={style["login-input-section-wrapper"]}>
         {isWrongPass && <WrongPassOrEmail />}

         <div className={style["login-input-wrapper"]}>
            <Input
               type="text"
               value={email}
               action={setEmail}
               placeholder={text.login.email}
               classes={inputClasses}
            />
         </div>
         <div className={style["login-input-wrapper"]}>
            <Input
               type={`${showPassword ? "text" : "password"}`}
               value={password}
               action={setPassword}
               placeholder={text.login.pasword}
               classes={inputClasses}
            />
            <FaButton
               action={togglePasswordVisibility}
               faIcon={showPassword ? faEyeSlash : faEye}
               styleClass={style["login-input-eye"]}
            />
         </div>
         <Button
            name={text.login.loginButton}
            action={submitLogin}
            classes={style["login-sizer"]}
         />
         <div>
            {text.login.needAcc}
            <span className={style["link-alike"]} onClick={goToRegister}>
               {text.login.registerLink}
            </span>
         </div>
      </div>
   );
};

export default LoginInput;
